import React from 'react'
import { range } from 'lodash'
import Layout from '../components/layout'
import LoremIpsum from '../components/loremipsum'

const IndexPage = () => {
  return (
    <Layout pageTitle="Jasa Software Custom | Jasa Program Custom | QSoft">
      <h1>Home Page</h1>
      {range(2).map(value => (
        <LoremIpsum key={value}/>
      ))}      
    </Layout>
  )
}

export default IndexPage
