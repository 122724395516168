import React, { Fragment } from 'react'

const LoremIpsum = () => {
  return (
    <Fragment>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus dui, a efficitur tortor porttitor sed. Morbi tellus leo, scelerisque ac urna eget, placerat blandit eros. Sed fermentum faucibus lectus, nec hendrerit urna convallis vitae. Donec sollicitudin lorem tellus, et commodo tellus efficitur eu. Quisque ac augue tortor. Duis at sapien ac arcu mattis tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut lectus sem, pharetra in sollicitudin vitae, dictum non felis.
      </p>
      <p>
        Suspendisse tellus eros, dignissim id massa nec, blandit posuere velit. In hendrerit lorem in sodales molestie. Donec lacinia vehicula nisi in sodales. Vivamus auctor ligula sit amet interdum iaculis. Suspendisse a felis mollis, placerat erat at, posuere lacus. Vivamus a dolor ut metus venenatis ornare sit amet ut diam. Nulla felis sem, porta a augue et, blandit cursus neque. Vivamus enim lacus, porttitor non erat vitae, placerat maximus arcu. Ut eget nunc odio.
      </p>
      <p>
        Nam vel odio fringilla, vehicula lectus ut, viverra nulla. Maecenas dictum urna quis faucibus ultricies. Nulla commodo varius ante et iaculis. Donec faucibus, lectus vitae aliquam gravida, ex enim posuere magna, ac efficitur lectus odio eu ex. Aliquam id euismod mi, ut vehicula massa. Aliquam ullamcorper vehicula nulla et aliquet. Nullam vitae leo non sem condimentum euismod non quis erat. Integer porttitor, lectus id faucibus efficitur, libero ipsum tristique ante, eget euismod risus turpis eu sem. Nullam in egestas mi, et volutpat nisl. Vestibulum nec faucibus ipsum. Donec orci nunc, tincidunt eu fringilla id, dignissim nec nisi.
      </p>
      <p>
        Quisque non volutpat tortor. Proin dictum lacus quis neque fringilla porta. Donec in blandit odio, et mattis urna. Nunc pulvinar scelerisque iaculis. Nulla a dolor venenatis, tincidunt urna sed, mollis odio. Aenean volutpat nisl ac ante fringilla suscipit. Quisque fermentum magna ac volutpat ultricies. Phasellus hendrerit, sapien sed tincidunt lacinia, nulla tellus venenatis massa, quis condimentum dui dolor et dolor. Praesent sit amet enim lacinia, ullamcorper purus a, accumsan augue. Nulla lobortis tempus sodales.
      </p>
      <p>
        Nulla porttitor ex felis. In ornare est in dolor semper, ac dapibus nulla convallis. Nam condimentum, ante quis tincidunt viverra, ante nunc malesuada nisl, eget posuere ex augue ac purus. Integer ornare lacinia iaculis. Vivamus at fringilla felis, in laoreet quam. Aliquam mollis turpis eu velit pellentesque tincidunt. Nam at mi nec urna suscipit laoreet et sit amet odio. Integer ut erat ut sapien pretium maximus accumsan quis elit. Vestibulum convallis lacus vitae tristique aliquam.
      </p>
    </Fragment>
  )
}

export default LoremIpsum